import "./School.scss";

import { Col, Row, Image } from "react-bootstrap";

import schoolImage from "../../media/school.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faSchool,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

export default function School() {
  return (
    <section className="school-section">
      <Row>
        <h1>I am currently studying at</h1>
      </Row>
      <Row>
        <Col className="school-column-1">
          <Row>
            <ul>
              <li>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faSchool}
                  />
                  <h1 className="tmed">ITS A. Volta</h1>
                </div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faLocationDot}
                  />
                  <h1 className="tmed">Via Monte Grappa, Trieste (TS)</h1>
                </div>
              </li>
              <li>
                <div>
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faWrench}
                  />
                  <h1 className="tmed">Information Technology</h1>
                </div>
              </li>
            </ul>
          </Row>
        </Col>
        <Col className="school-column-2">
          <Row>
            <Image src={schoolImage}></Image>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
