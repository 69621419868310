import { Link } from "react-router-dom";
import "./Menu.scss";

export default function Menu() {
  return (
    <div className="menu--closed" id="menu">
      <div className="menu__content">
        <ul>
          <li>
            <Link
              onClick={() => {
                document
                  .getElementById("home")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                document
                  .getElementById("aboutme")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              About Me
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                document
                  .getElementById("skills")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              My skills
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                document
                  .getElementById("hobbys")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Hobbys
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
