import Hero from "../../components/home/Hero";
import Space from "../../components/home/Space";

import Menu from "../../components/menu/Menu";
import AppBar from "../../components/home/AppBar";

import "./Home.scss";
import Footer from "../../components/home/Footer";
import AboutMe from "../../components/home/AboutMe";
import Skills from "../../components/home/Skills";
import Hobbys from "../../components/home/Hobbys";
import Projects from "../../components/home/Projects";
import School from "../../components/home/School";
import Ambitions from "../../components/home/Ambitions";

const Home = () => {
  return (
    <section className="home">
      <AppBar />
      <Menu />
      <Space />
      <Hero />
      <AboutMe />
      <Skills />
      <Ambitions />
      <Projects />
      <Hobbys />
      <School />
      <Footer />
    </section>
  );
};

export default Home;
