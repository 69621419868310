import { Col, Row, Image, Form, Button, Alert } from "react-bootstrap";
import {
  faInstagram,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import "./Footer.scss";

import meImage from "../../media/me1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function Footer() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(true);

  const userRef = useRef();
  const recaptchaRef = useRef();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email) => {
    if (email === "") return true;
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      setShow(true);
      setErrMessage("Fill all the fields");
      console.log("Fill all the fields");
      recaptchaRef.current.reset();
    } else if (!validateEmail(email)) {
      setShow(true);
      setErrMessage("Email address is not valid");
      console.log("Email address is not valid");
      recaptchaRef.current.reset();
    } else {
      setErrMessage("");
      recaptchaRef.current.execute();
    }
  };

  const sendEmail = async () => {
    console.log("Sending email");
    const response = await axios.post(
      "https://www.bl4ckdrake.com/api/mail",
      JSON.stringify({
        name: name,
        email: email,
        message: message,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://www.bl4ckdrake.com/api/mail",
        },
      }
    );

    if (response.status === 200) {
      console.log("Email sent");
      setSuccessMessage("Email sent successfully!");
    } else {
      console.log("Error while sending email.");
      setErrMessage("Error while sending email.");
    }

    setErrMessage("");
    setName("");
    setEmail("");
    setMessage("");
  };
  return (
    <section id="contact" className="footer">
      {show && errMessage ? (
        <Alert
          variant="danger"
          className="contact-alert"
          onClose={() => setShow(false)}
          dismissible
        >
          {errMessage}
        </Alert>
      ) : null}
      {show && successMessage ? (
        <Alert
          variant="success"
          className="contact-alert"
          onClose={() => setShow(false)}
          dismissible
        >
          {successMessage}
        </Alert>
      ) : null}
      <Row className="footer-row">
        <Col className="col">
          <h1 className="tw">Contact Me</h1>
          <Form className="col__content" autoFocus={false}>
            <Form.Group className="mb-3" controlId="formBasicFirstName">
              <Form.Label className="tw">Name</Form.Label>
              <Form.Control
                onFocus={() => {
                  userRef.current.blur();
                  window.scrollTo(0, 0);
                }}
                tabIndex={-1}
                autoFocus={false}
                preventDefault={true}
                ref={userRef}
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="tw">Email address</Form.Label>
              <Form.Control
                onFocus={() => {
                  userRef.current.blur();
                  window.scrollTo(0, 0);
                }}
                tabIndex={-1}
                autoFocus={false}
                preventDefault={true}
                ref={userRef}
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicMessage">
              <Form.Label className="tw">Message</Form.Label>
              <Form.Control
                onFocus={() => {
                  userRef.current.blur();
                  window.scrollTo(0, 0);
                }}
                tabIndex={-1}
                autoFocus={false}
                preventDefault={true}
                ref={userRef}
                as="textarea"
                type="text"
                placeholder="Enter message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </Button>
          </Form>
        </Col>
        <Col className="col">
          <h1 className="tw">Information</h1>
          <Image style={{ width: "150px" }} src={meImage} roundedCircle />
          <p className="tw">
            <strong>Where:</strong> Trieste, Italy
          </p>
          <p className="tw">
            <strong>Phone:</strong> +39 371 415 7051
          </p>
          <p className="tw">
            <strong>Email:</strong> 0matteo.goldin6@gmail.com
          </p>

          <h1 className="tw">Follow Me</h1>
          <Link to={"https://www.instagram.com/_goldown/"}>
            <FontAwesomeIcon
              className="ig-icon"
              icon={faInstagram}
              size="2xl"
            />
          </Link>
        </Col>
      </Row>
      <small className="tw">© 2024 Bl4ckDrake</small>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LcMg3EpAAAAAIepxKC4rh91JU5BnjxwUqVSrE0w"
        onChange={sendEmail}
      />
    </section>
  );
}
