import * as THREE from "three";

import { useThree } from "react-three-fiber";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

function CameraControls() {
  const [scrollCount, setScrollCount] = useState(0);
  const { camera } = useThree();
  const curve = CameraEllipsePoints();

  const points = curve.getPoints(400);
  var i = points.length - 1;
  points.forEach((p) => {
    p.z = -p.y;
    p.y = 0;
  });

  for (var k = 0; k < i / 4; k++) {
    points[k].y = -k;
    points[k + i / 4].y = -(i / 4 - k);
    points[k + i / 2].y = k;
    points[k + i * (3 / 4)].y = i / 4 - k;
  }

  /*useEffect(() => {
    camera.position.set(points[0].x, points[0].y, points[0].z);
  });*/

  if (scrollCount === 0) {
    camera.position.set(points[0].x, points[0].y, points[0].z);
    camera.lookAt(0, 0, 0);
  }

  useEffect(() => {
    const onScroll = () => {
      if (scrollCount === 598) setScrollCount(0);
      else setScrollCount(scrollCount + 2);

      var proportion = Math.floor((window.scrollY * 400) / window.scrollMaxY);

      camera.position.x = points[proportion].x;
      camera.position.y = points[proportion].y;
      camera.position.z = points[proportion].z;
      camera.lookAt(0, 0, 0);
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return null;
}

function CameraEllipsePoints() {
  const xRadius = 40;
  const yRadius = 38;
  const startAngle = 0;
  const endAngle = Math.PI * 2;

  return new THREE.EllipseCurve(
    0,
    0,
    xRadius,
    yRadius,
    startAngle,
    endAngle,
    false,
    0
    //Math.PI * (3 / 4)
  );
}

function CameraEllipseLine() {
  const curve = CameraEllipsePoints();

  const material = new THREE.LineBasicMaterial({ color: 0xffffff });

  const points = curve.getPoints(600);

  var i = points.length - 1;
  points.forEach((p) => {
    p.z = -p.y;
    p.y = 10;
  });

  //points[0].y = -i;
  //points[i*2].y = i;
  for (var k = 0; k < i / 4; k++) {
    points[k].y = -k;
    //console.log(i);
    points[k + i / 4].y = -(i / 4 - k);
    points[k + i / 2].y = k;
    points[k + i * (3 / 4)].y = i / 4 - k;
  }

  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const line = new THREE.Line(geometry, material);

  return <mesh geometry={geometry} material={material} />;
}

function CameraControlsMobile() {
  const { camera } = useThree();

  camera.position.set(-34.745260577527645, 33, 18.827429400431487);
  camera.lookAt(0, 0, 0);

  return null;
}

export default function Camera() {
  return (
    <>
      {isMobile ? <CameraControlsMobile /> : <CameraControls />}
      {isMobile ? null : <CameraEllipseLine />}
    </>
  );
}
