import * as THREE from "three";

import React from "react";
import { useFrame, useLoader } from "react-three-fiber";
import { useRef } from "react";
import { isMobile } from "react-device-detect";

const ORBIT_POINTS = 5000;

const DISTANCE = 95;
/* Rotation on Axis */
const BASE_ROTATION = 0.001;
const ROTATION_MULTIPLIER = 2.18;
/* Orbit around the sun */
const BASE_ORBIT = 1;
const ORBIT_MULTIPLIER = 0.008;

function EllipsePoint() {
  const xRadius = DISTANCE;
  const yRadius = DISTANCE - 1;
  const startAngle = 0;
  const endAngle = Math.PI * 2;

  const curve = new THREE.EllipseCurve(
    0,
    0,
    xRadius,
    yRadius,
    startAngle,
    endAngle,
    false,
    0
  );

  //curve.rotation.set(0, 1, 0);

  return curve;
}

function Ellipse() {
  const curve = EllipsePoint();

  const material = new THREE.LineBasicMaterial({ color: 0xffffff });

  const points = curve.getPoints(100);
  points.forEach((p) => {
    p.z = -p.y;
    p.y = 0;
  });
  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const line = new THREE.Line(geometry, material);

  return <mesh geometry={geometry} material={material} />;
}

function SaturnMesh() {
  const saturnRef = useRef();
  const saturnRingRef = useRef();
  const ellipse = EllipsePoint();
  const ellipsePoints = ellipse.getPoints(ORBIT_POINTS);

  ellipsePoints.forEach((p) => {
    p.z = -p.y;
    p.y = 0;
  });

  const [sphereMap] = useLoader(THREE.TextureLoader, [
    require("../../../media/space-textures/saturn.jpg"),
  ]);

  const [ringMap] = useLoader(THREE.TextureLoader, [
    require("../../../media/space-textures/saturn_ring.png"),
  ]);

  var orbitCount = Math.floor(Math.random() * ORBIT_POINTS) - 1;
  ringMap.rotation = Math.PI / 2;

  useFrame(({ state, delta }) => {
    orbitCount += BASE_ORBIT * ORBIT_MULTIPLIER;

    if (orbitCount >= ORBIT_POINTS - 1) orbitCount = 0;

    saturnRef.current.rotation.y -= BASE_ROTATION * ROTATION_MULTIPLIER;
    saturnRef.current.position.x = ellipsePoints[Math.floor(orbitCount)].x;
    saturnRef.current.position.z = ellipsePoints[Math.floor(orbitCount)].z;

    // ring
    saturnRingRef.current.position.x = ellipsePoints[Math.floor(orbitCount)].x;
    saturnRingRef.current.position.z = ellipsePoints[Math.floor(orbitCount)].z;
  });

  /*
  <mesh ref={saturnRef} position={[95, 0, 0]} rotateY={20}>
        <ringBufferGeometry args={[0.7, 1, 32]} />
        <meshStandardMaterial map={ringMap} />
      </mesh>
  
  */

  return (
    <group>
      <mesh ref={saturnRef} position={[95, 0, 0]}>
        <sphereBufferGeometry args={[9, 32, 32]} />
        <meshStandardMaterial map={sphereMap} />
      </mesh>
      <mesh
        ref={saturnRingRef}
        position={[95, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <torusBufferGeometry args={[15, 2, 2.5, 100]} />
        <meshStandardMaterial map={ringMap} rotation={Math.PI / 2} />
      </mesh>
    </group>
  );
}

export default function Saturn() {
  return (
    <>
      {isMobile ? null : <Ellipse />}
      <SaturnMesh />
    </>
  );
}
