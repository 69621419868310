import { Col, Row, Image } from "react-bootstrap";
import "./Hobbys.scss";

import codeImage from "../../media/code.jpg";
import skiingImage from "../../media/skiing.jpg";

export default function SkillsDescription() {
  return (
    <div id="hobbys" className="hobbys-section">
      <Row className="row-1">
        <Col>
          <h1 className="tw">Hobbys</h1>
        </Col>
      </Row>
      <Row className="row-2">
        <Col className="column">
          <Row className="col__row">
            <Image className="images" src={codeImage} />
          </Row>
          <Row className="col__row">
            <p className="tw">
              I have also been xc-skiing for 8 years. I love skiing in the
              mountains with friends. I enjoy skiing for hours and look back at
              what I am able to do. I like the feeling of the wind, the sun and
              the speed. I enjoy running and cycling long distances because of
              the feeling of accomplishment. Training is my passion.
            </p>
          </Row>
        </Col>
        <Col className="column">
          <Row className="col__row">
            <p className="tw">
              Since I was 13 years old, I have been programming. At this moment,
              I am able to develop applications. I love to learn new
              technologies and programming languages. I love to create new
              things. I am also able to develop websites and mobile
              applications. I am familiar with the following technologies: Java,
              C++, Python, Dart, Flutter, Spring Boot, Flask, and more. I am
              also familiar with the Linux environment. I can setup Linux
              servers and host websites with Nginx.
            </p>
          </Row>
          <Row className="col__row">
            <Image className="images" src={skiingImage} />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
