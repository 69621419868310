import "./AppBar.scss";

const AppBar = () => {
  const toggleMenu = () => {
    const menu = document.getElementById("menu");
    const hamburger = document.getElementById("hamburger");
    if (menu.classList.contains("menu--closed")) {
      menu.classList.remove("menu--closed");
      menu.classList.add("menu--open");
      hamburger.classList.add("hamburger--open");
    } else {
      menu.classList.remove("menu--open");
      menu.classList.add("menu--closed");
      hamburger.classList.remove("hamburger--open");
    }
  };

  return (
    <div className="app-bar">
      <div className="app-bar__content">
        <div className="app-bar__logo">
          <img src={require("../../media/Bl4ckdrake.png")} alt="logo" />
        </div>
        <div className="app-bar__menu">
          <input type="checkbox" id="hamburger" onClick={toggleMenu} />
          <label for="hamburger" class="hamburger">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default AppBar;
