import { useEffect } from "react";
import "./Skills.scss";
import { Image, ProgressBar } from "react-bootstrap";

import reactImg from "../../media/logos/react.png";
import mariadbImg from "../../media/logos/mariadb.png";
import javaImg from "../../media/logos/java.png";
import pythonImg from "../../media/logos/python.png";
import gitImg from "../../media/logos/git.png";
import githubImg from "../../media/logos/github.png";
import httpImg from "../../media/logos/http.png";
import nginxImg from "../../media/logos/nginx.png";
import linuxImg from "../../media/logos/linux.png";
import flutterImg from "../../media/logos/flutter.png";
import $ from "jquery";
import { isMobile } from "react-device-detect";

const SKILLS = [
  ["React", 6, reactImg],
  ["MariaDB", 6, mariadbImg],
  ["Java", 8, javaImg],
  ["Python", 7, pythonImg],
  ["Git", 7, gitImg],
  ["GitHub", 8, githubImg],
  ["HTTP", 6, httpImg],
  ["Nginx", 6, nginxImg],
  ["Linux", 7, linuxImg],
  ["Flutter", 6, flutterImg],
];

export default function Skills() {
  useEffect(() => {
    if (isMobile) return;

    var $zone = $(".zone");
    var diametre = 100;
    var canvasWidth = $zone.width() - diametre;
    var canvasHeight = $zone.height() - diametre;

    var circleCoordinate = [];

    function createNewCircle() {
      var overlap = true;
      var loop = true;

      while (overlap) {
        overlap = false;
        var x = Math.round(Math.random() * canvasWidth);
        var y = Math.round(Math.random() * canvasHeight);
        for (var k = 0; k < circleCoordinate.length; k++) {
          var xa0 = x;
          var xa1 = x + diametre;
          var xb0 = circleCoordinate[k].x;
          var xb1 = circleCoordinate[k].x + diametre;

          var ya0 = y;
          var ya1 = y + diametre;
          var yb0 = circleCoordinate[k].y;
          var yb1 = circleCoordinate[k].y + diametre;

          if (
            ((xa0 >= xb0 && xa0 <= xb1) || (xa0 <= xb0 && xa1 >= xb0)) &&
            ((ya0 >= yb0 && ya0 <= yb1) || (ya0 <= yb0 && ya1 >= yb0))
          ) {
            overlap = true;
          }
        }
      }
      var circle = { x: x, y: y };
      circleCoordinate.push(circle);

      return [circle, loop];
    }

    for (var i = 0; i < SKILLS.length; i++) {
      var circle = createNewCircle();

      var object = document.getElementById(i + 1);
      //object.style.position = "absolute";
      object.style.top = circle[0].y + "px";
      object.style.left = circle[0].x + "px";
    }
  }, []);

  return (
    <section id="skills" className="skills-section">
      {isMobile ? null : (
        <div className="skills-column-1">
          <ul className="zone">
            {SKILLS.map((skill, index) => (
              <li className="circle" id={index + 1}>
                <Image
                  src={skill[2]}
                  style={{ maxWidth: "80px" }}
                  alt="skill"
                ></Image>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="skills-column-2">
        <div className="skill-list">
          <h1>My Skills</h1>
          <ul>
            {SKILLS.map((skill, index) => (
              <li key={index}>
                <div className="skill-item">
                  <div>{skill[0]}</div>
                  <ProgressBar animated now={skill[1] * 10} id={index + 1} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
