import { Button, Col, Row } from "react-bootstrap";
import "./AboutMe.scss";

export default function AboutMe() {
  return (
    <section id="aboutme" className="aboutme-section">
      <h1 className="tw tvbig">About Me</h1>
      <Row className="aboutme-row">
        <Col className="aboutme-column">
          <div className="aboutme-column__content">
            <h1 className="tw">Hi, there!</h1>
            <h2 className="tw tvbig">I am Matteo Goldin</h2>
            <p className="tw tsmall">
              Born in Trieste, I am (almost) 18 years old. I am a student at ITS
              A. Volta, in the IT department. I am a developer, a student and an
              athlete.
            </p>
            <p className="tw tsmall">
              I started programming when I was 13 years old, and I have never
              stopped since then. I started with Python, then I moved to C++ and
              then Java. I have also learned some web development languages like
              HTML, CSS and JavaScript. I got familiar with backend development
              with Spring Boot and I have also learned some basics of databases
              like SQL or MariaDB. I have also experienced some technologies
              like Flutter. I am able to use Git and GitHub. I am also familiar
              with the Linux environment.
            </p>
            <Button
              variant="primary"
              onClick={() => {
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Contact Me
            </Button>
          </div>
        </Col>
        <Col className="aboutme-column">
          <img
            src={require("../../media/me.jpg")}
            alt="me"
            style={{
              width: "40%",
              height: "auto",
              borderRadius: "1rem",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          />
        </Col>
      </Row>
    </section>
  );
}
