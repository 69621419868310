import "./Ambitions.scss";

import { Row, Col, Image } from "react-bootstrap";

import ciscoImage from "../../media/ITE.png";
import { Link } from "react-router-dom";

export default function Ambitions() {
  return (
    <section className="ambitions-section">
      <Col className="ambitions-column-1">
        <Row>
          <h1 className="tw">What am I able to do</h1>
        </Row>
        <Row>
          <p className="tw">
            I'm able to develop software in Java, C++, Python, and Dart. I'm
            also familiar with full-stack application development. I'm able to
            use databases and create APIs with Java Spring Boot or Python Flask.
            I have also experienced some technologies like Flutter. I can create
            a mobile application or website and interface them with an API. I am
            able to use Git and GitHub. I am also familiar with the Linux
            environment. I can setup Linux servers and host websites with Nginx.
          </p>
        </Row>
      </Col>
      <Col className="ambitions-column-2">
        <Row>
          <h1 className="tw">Cisco ITE Certification</h1>
        </Row>
        <Row>
          <Link
            id="ciscoCertBtn"
            to={
              "https://www.credly.com/badges/6b76860f-b443-4f8b-b698-47d6efb2bf30/wallet"
            }
          >
            <Image src={ciscoImage} alt="Cisco IT Essentials" fluid />
          </Link>
        </Row>
      </Col>
    </section>
  );
}
