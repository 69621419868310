import { Canvas } from "react-three-fiber";

import React from "react";

import "./Space.scss";
import Camera from "../space/camera/Camera";
import Earth from "../space/planets/EarthDay";
import Mars from "../space/planets/Mars";
import Sun from "../space/stars/Sun";
import Saturn from "../space/planets/Saturn";
import Neptune from "../space/planets/Neptune";
import Jupiter from "../space/planets/Jupiter";
import Venus from "../space/planets/Venus";
import Mercury from "../space/planets/Mercury";

function Space() {
  return (
    <section className="space">
      <Canvas className="space__image">
        <Camera />
        <Earth />
        <Mars />
        <Sun />
        <Saturn />
        <Neptune />
        <Jupiter />
        <Venus />
        <Mercury />
        <ambientLight />
        <light position={[0, 0, 0]} />
      </Canvas>
    </section>
  );
}

export default Space;
