import "./Hero.scss";

import { TypeAnimation } from "react-type-animation";

export default function Hero() {
  return (
    <div id="home" className="hero">
      <div className="iam-div">
        <div className="iam-text">
          <h1 className="tw" style={{ marginRight: "0.5rem" }}>
            I am
          </h1>
          <h1 className="tw">Bl</h1>
          <h1 className="tr">4</h1>
          <h1 className="tw">ckDrake</h1>
        </div>
        <TypeAnimation
          className="animated-text"
          sequence={[
            // Same substring at the start will only be typed once, initially
            "I am Developer",
            1000,
            "I am Student",
            1000,
            "I am Athlete",
            1000,
          ]}
          speed={50}
          repeat={Infinity}
        />
      </div>
      <div className="madewith-div">
        <small className="tw">Made with Three.js</small>
      </div>
    </div>
  );
}
