import * as THREE from "three";

import React from "react";
import { useFrame, useLoader } from "react-three-fiber";
import { useRef } from "react";

/* Rotation on Axis */
const BASE_ROTATION = 0.001;
const ROTATION_MULTIPLIER = 0.04;

function SunMesh() {
  const sunRef = useRef();

  useFrame(({ state, delta }) => {
    sunRef.current.rotation.y -= BASE_ROTATION * ROTATION_MULTIPLIER;
  });
  const [sphereMap] = useLoader(THREE.TextureLoader, [
    require("../../../media/space-textures/sun.jpg"),
  ]);
  return (
    <mesh ref={sunRef} position={[0, 0, 0]}>
      <sphereBufferGeometry args={[2.5, 32, 32]} />
      <meshStandardMaterial map={sphereMap} />
    </mesh>
  );
}

export default function Sun() {
  return (
    <>
      <SunMesh />
    </>
  );
}
