import * as THREE from "three";

import React from "react";
import { useFrame, useLoader } from "react-three-fiber";
import { useRef } from "react";
import { isMobile } from "react-device-detect";

const ORBIT_POINTS = 5000;

const DISTANCE = 4;
/* Rotation on Axis */
const BASE_ROTATION = 0.001;
const ROTATION_MULTIPLIER = 0.017;
/* Orbit around the sun */
const BASE_ORBIT = 1;
const ORBIT_MULTIPLIER = 1;

function EllipsePoint() {
  const xRadius = DISTANCE;
  const yRadius = DISTANCE - 1;
  const startAngle = 0;
  const endAngle = Math.PI * 2;

  const curve = new THREE.EllipseCurve(
    0,
    0,
    xRadius,
    yRadius,
    startAngle,
    endAngle,
    false,
    0
  );

  //curve.rotation.set(0, 1, 0);

  return curve;
}

function Ellipse() {
  const curve = EllipsePoint();

  const material = new THREE.LineBasicMaterial({ color: 0xffffff });

  const points = curve.getPoints(100);
  points.forEach((p) => {
    p.z = -p.y;
    p.y = 0;
  });
  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const line = new THREE.Line(geometry, material);

  return <mesh geometry={geometry} material={material} />;
}

function MercuryMesh() {
  const mercuryRef = useRef();
  const ellipse = EllipsePoint();
  const ellipsePoints = ellipse.getPoints(ORBIT_POINTS);

  ellipsePoints.forEach((p) => {
    p.z = -p.y;
    p.y = 0;
  });

  const [sphereMap] = useLoader(THREE.TextureLoader, [
    require("../../../media/space-textures/mercury.jpg"),
  ]);

  var orbitCount = Math.floor(Math.random() * ORBIT_POINTS) - 1;

  useFrame(({ state, delta }) => {
    orbitCount += BASE_ORBIT * ORBIT_MULTIPLIER;

    if (orbitCount >= ORBIT_POINTS - 1) orbitCount = 0;

    mercuryRef.current.rotation.y -= BASE_ROTATION * ROTATION_MULTIPLIER;
    mercuryRef.current.position.x = ellipsePoints[Math.floor(orbitCount)].x;
    mercuryRef.current.position.z = ellipsePoints[Math.floor(orbitCount)].z;
  });

  return (
    <mesh ref={mercuryRef} position={[4, 0, 0]}>
      <sphereBufferGeometry args={[0.5, 32, 32]} />
      <meshStandardMaterial map={sphereMap} />
    </mesh>
  );
}

export default function Mercury() {
  return (
    <>
      {isMobile ? null : <Ellipse />}
      <MercuryMesh />
    </>
  );
}
