import "./Projects.scss";

import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";

import pioveopicinaImage from "../../media/projects/pioveopicina.png";
import { Link } from "react-router-dom";

export default function Projects() {
  return (
    <section className="projects-section">
      <Row>
        <h1>Check out one of my projects</h1>
      </Row>
      <Row className="projects-row-2">
        <div className="projects-row-2__content">
          <a href="https://www.pioveopicina.it">
            <Image src={pioveopicinaImage} className="project-image"></Image>
          </a>
        </div>
      </Row>
    </section>
  );
}
